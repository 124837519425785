import styled from "styled-components"
import { Row } from "styled-bootstrap-grid"

const ClientsWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const ClientsContent = styled.div`
  padding: ${({
    theme: {
      content: {
        padding: { mobile },
      },
    },
  }) => mobile};
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};
  padding-top: 6rem;

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`

const Title = styled.h1`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
`

const Paragraph1 = styled.p`
  margin: 0;
  margin-top: 5rem;
  margin-bottom: 10rem;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s52};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s62};
`

const LogoWrapper = styled.div`
  max-width: 6.25rem;
  height: 5rem;
  margin: ${({ theme: { spacing } }) => spacing.s15};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 576px) {
    max-width: 10rem;
    height: 90px;
  }
`
const Logo = styled.img`
  max-width: 100%;
  height: auto;
`

const LogoList = styled(Row)`
  margin: -${({ theme: { spacing } }) => spacing.s30};
  margin-top: 0;
  margin-bottom: 0;
`

const Margin = styled.div`
  @media only screen and (min-width: 1400px) {
    margin: 0 100px;
  }
`

export {
    Margin,
    LogoList,
    Logo,
    LogoWrapper,
    Paragraph1,
    Title,
    ClientsContent,
    ClientsWrapper
}