import {
    Margin,
    LogoList,
    Logo,
    LogoWrapper,
    Paragraph1,
    Title,
    ClientsContent,
    ClientsWrapper
} from '../styles/clients/style'
import Layout from "../components/layout"
import React from "react"
import {  Col, Container } from "styled-bootstrap-grid"

const Clients = () => {
  return (
    <Layout>
      <ClientsWrapper>
        <ClientsContent>
          <Title>Our Experience</Title>
          <Paragraph1>More than 350 banks worldwide.</Paragraph1>
          <Margin>
            <Container fluid>
              <LogoList>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/logo1.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/first_bank.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/uob.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/arab_bank.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/standard_chartered.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/kib.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/vietcombank.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/bank_of_beirut.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/china.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/xac.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/qfb.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/helm.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/itau.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/erste.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/abc.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/etihad.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/santanderpng.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/mcb.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/adib.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/capitec.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/chase.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/icici.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/money.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/nbk.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/absa.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/callbankpng.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/ambank.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/emirates.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/sbi.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/lloydspng.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/bancolombia.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/aib.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/rbs.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/ing.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/muscat.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/mashreq.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/dbs.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/coventry.png" />
                  </LogoWrapper>
                </Col>
                <Col xs={4} sm={4} md={2}>
                  <LogoWrapper>
                    <Logo src="/bbv.png" />
                  </LogoWrapper>
                </Col>
              </LogoList>
            </Container>
          </Margin>
        </ClientsContent>
      </ClientsWrapper>
    </Layout>
  )
}

export default Clients
